import React, { useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import Modal from './Modal';
import SubmitButton from './SubmitButton';
import FormikTextField from './FormikTextField';
import FormikCheckbox from './FormikCheckbox';
import { registrationWebinarFormScheme } from '../utils/validations';
import { postData, getData } from '../utils/fetch';

const RegistrationWebinarForm = ({ pageContext }) => {
  const [gdprChecked, setGdprChecked] = useState(false);
  const [modalText, setModalText] = useState(undefined);
  return (
    <>
      {modalText && <Modal closeModal={() => setModalText(undefined)} text={modalText} />}

      <Formik
        initialValues={{
          email: '',
          agreement: false,
        }}
        validationSchema={registrationWebinarFormScheme}
        onSubmit={(values, formikActions) => {
          postData(`${process.env.FIREBASE_API_URL}/public/webinar/request`, {
            ...values,
            webinarKey: pageContext.key,
            dateOfRegistration: dayjs().format('DD.MM.YYYY HH:mm'),
          }).then(() => {
            formikActions.setSubmitting(false);
            formikActions.resetForm();
            setModalText(
              `Vaše registrace na webinář proběhla úspěšně. Během několika minut obdržíte na Vámi zadaný email potvrzení účasti.`
            );
          });
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="ContactForm">
            <FormikTextField
              name="email"
              floatingLabel="formEmail"
              onBlur={() => {
                getData(`${process.env.FIREBASE_API_URL}/public/gdpr/check/${values.email}`)
                  .then((response) => {
                    if (response.error) {
                      setGdprChecked(false);
                      setFieldValue('agreement', false);
                    } else {
                      setGdprChecked(true);
                      setFieldValue('agreement', true);
                    }
                  })
                  .catch(() => {
                    setGdprChecked(false);
                    setFieldValue('agreement', false);
                  });
              }}
            />

            {!gdprChecked && (
              <FormikCheckbox
                name="agreement"
                floatingLabel={
                  'Souhlasím, aby společnost SQN s.r.o., se sídlem Kubelíkova 1224/42, 130 00 Praha 3, IČ 04965850, (dále jen „správce údajů”), zpracovávala mnou poskytnuté osobní údaje, a to v souladu s obecným nařízením o ochraně osobních údajů [EU] 2016/679. Pravidla o ochraně osobních údajů naleznete <a href="/gdpr-rules" target="_blank">zde</a>.'
                }
              />
            )}
            <SubmitButton isSubmitting={isSubmitting} sendMsg="logIn" />
          </form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationWebinarForm;
