import React from 'react';
import FooterLogo from './FooterLogo';
import Contact from './Contact';
import Logo from './Logo';
import withIntl from '../i18n/withIntl';
import WebinarForm from './WebinarForm';
import '../../assets/styles/Web.scss';

const WebinarRegistration = ({ pageContext }) => (
  <>
    <section className="Section Section--registration Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">Registrační formulář na webinář</h2>
        <div className="Table Table--registration">
          <div className="Table-row">
            <div className="Table-cell">Téma webináře:</div>
            <div className="Table-cell Table-cell--highlight">{pageContext.name}</div>
          </div>
          <div className="Table-row">
            <div className="Table-cell">Datum konání:</div>
            <div className="Table-cell">{pageContext.dateFreeText}</div>
          </div>
          <div className="Table-row">
            <div className="Table-cell">Místo konání:</div>
            <div className="Table-cell">{pageContext.place}</div>
          </div>
          <div className="Table-row">
            <div className="Table-cell">Cena:</div>
            <div className="Table-cell">{pageContext.price}</div>
          </div>
        </div>
        <h3 className="Section-subTitle">Informace o účastníkovi</h3>
        <WebinarForm pageContext={pageContext} />
      </div>
    </section>
    <footer className="Footer">
      <FooterLogo />
      <Contact />
    </footer>
  </>
);

export default withIntl(WebinarRegistration, 'cs');
